import * as React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import BaseLayout from "../../../components/layout"
import Seo from "../../../components/seo";
import HorizontalRule from "../../../components/horizontal-rule";
import BookSummary from "../../../components/book-summary";
import NewsletterSignup from "../../../components/newsletter-signup";
import ChapterSummary from "../../../components/chapter-summary";
import BreadcrumbTrail from "../../../components/breadcrumb-trail";


const WritingLibraryPage = (props) => {
  let data = useStaticQuery(pageQuery);
  let featuredChapter = data.featuredChapter.nodes[0];

  let breadcrumbList = [
    [
      {
        title: 'Home',
        url: '/'
      },
      {
        title: 'Library',
      }
    ]
  ]

  return (
    <BaseLayout>
      <Seo title="Library" desc="Search through the list of books, essays, and story collections Zack has published." pathname={props.location.pathname}></Seo>
      <div className="layout-base-w app-p-base">
        <BreadcrumbTrail breadcrumbTrail={breadcrumbList[0]}></BreadcrumbTrail>
        <div className="text-mint mb-28">
          <HorizontalRule ruleType="1"></HorizontalRule>
          <h1 style={{'maxWidth': '620px'}} className="text-header-1 w-full">Library</h1>
        </div>

        {data.allCollections.nodes.slice(0, 2).map((collection, index) => (
          <div key={index} className="mb-40">
            <BookSummary book={collection} summaryLayout="list" showCover={true}></BookSummary>
          </div>
        ))}

        <div className="my-40">
          <NewsletterSignup></NewsletterSignup>
        </div>

        {data.allCollections.nodes.slice(2, data.allCollections.nodes.length).map((collection, index) => (
          <div key={index} className="mb-40">
            <BookSummary book={collection} summaryLayout="list" showCover={true}></BookSummary>
          </div>
        ))}

        <div className="text-mint my-40">
          <HorizontalRule ruleType="4"></HorizontalRule>
        </div>

        <div className="flex justify-center mt-40">
          <ChapterSummary chapter={featuredChapter} summaryType="cta"></ChapterSummary>
        </div>

      </div>
    </BaseLayout>
  )
}

export default WritingLibraryPage

export const pageQuery = graphql`
  fragment CollectionFields on Collections {
    id
    title
    published_date
    is_published
    highlight_book
    purchase {
      display
      url
      button_text
    }
    sort_order
    book_cover {
      portrait
      landscape
      landscape_backdrop
    }
    book_type
    genres
    summary
    seo_description
    seo_image
  }

  query {
    allCollections(
      sort: { order: ASC, fields: [sort_order] }
      filter: { highlight_book: { eq: true } }
    ) {
      nodes {
        ...CollectionFields
      }
    }
  
    featuredChapter: allMdx(
      sort: { fields: [frontmatter___published_date], order: DESC }
      filter: { frontmatter: { published: { eq: true }, record_type: { eq: "story" } } }
      limit: 1
    ) {
      nodes {
        ...ArticleSnippet
      }
    }
  }
`